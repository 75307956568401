import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import placeholder from '../images/placeholder.png';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import TimerIcon from '@material-ui/icons/Timer';
import CakeIcon from '@material-ui/icons/Cake';
import CategoryIcon from '@material-ui/icons/Category';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BusinessIcon from '@material-ui/icons/Business';
import ClassIcon from '@material-ui/icons/Class';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { checkPermissions } from '../helper';

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
		margin: 10,
	},
	media: {
		height: 140,
	},
	texteTitle: {
		color: "#ffffff",
	},
	dialogPaper: {
		width: '80vh',
		height: '80vh',
	}
});

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function MediaCard(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [openImage, setOpenImage] = React.useState(false);

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClick = () => {
		setOpen(!open);
	};

	const handleImageClick = () => {
		setOpenImage(!openImage);
	};

	const handleImageClose = () => {
		setOpenImage(false);
		console.log(openImage);
	};

	return (
		<React.Fragment>
			<Card className={classes.root}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={props.item.image ? props.item.image : placeholder}
						title={props.item.nom ? props.item.nom : ''}
						onClick={handleImageClick}
					/>
					<CardContent>
						<Typography gutterBottom variant="h5" component="h2">
							{props.item.nom} {props.item.code ? ' - ' + props.item.code : ''}
						</Typography>
						<Typography gutterBottom variant="h6" component="h6">
							{props.item.age &&
								props.item.age
									.split(',')
									.map((e) => <Chip key={e} label={e} color="default" style={{ marginRight: 5 }} />)}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p" className={classes.p}>
							{props.item.resume ? props.item.resume.substring(0, 100) + '...' : ''}
						</Typography>
						<Typography gutterBottom variant="h6" component="h6">
							{props.item.mots_cles &&
								props.item.mots_cles
									.split(',')
									.map((e) => (
										<Chip
											key={e}
											label={e.toUpperCase()}
											color="primary"
											style={{ marginRight: 5, color: '#fff' }}
										/>
									))}
						</Typography>
						<ListItem button onClick={handleClick}>
							<ListItemIcon>
								<AddCircleOutlineIcon />
							</ListItemIcon>
							<ListItemText primary="En savoir plus" />
							{open ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List>
								{props.item.type && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<ClassIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Type" secondary={props.item.type} />
									</ListItem>
								)}
								{props.item.nbrJoueur && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<PeopleAltIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Nombre de joueurs" secondary={props.item.nbrJoueur} />
									</ListItem>
								)}
								{props.item.fabricant && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<BusinessIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Fabricant" secondary={props.item.fabricant} />
									</ListItem>
								)}
								{props.item.categorie && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<CategoryIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Catégorie" secondary={props.item.categorie} />
									</ListItem>
								)}
								{props.item.convenance && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<CakeIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Convenance" secondary={props.item.convenance} />
									</ListItem>
								)}
								{props.item.duree && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<TimerIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Durée" secondary={props.item.duree} />
									</ListItem>
								)}
								{props.item.mecanisme && (
									<ListItem>
										<ListItemAvatar>
											<Avatar>
												<PermDataSettingIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary="Mécanisme" secondary={props.item.mecanisme} />
									</ListItem>
								)}
							</List>
						</Collapse>
					</CardContent>
				</CardActionArea>
				<CardActions>
					{ !checkPermissions() && <Button
						to={`account/bookings/${props.item._id}`}
						component={Link}
						title="Réserver"
						color="secondary"
						variant={'contained'}
						fullWidth
					>
						{props.btnText}
					</Button> }
				</CardActions>
			</Card>
			<Dialog aria-labelledby="customized-dialog-title" open={openImage} fullWidth={true} maxWidth={'lg'}>
				<DialogTitle id="customized-dialog-title" onClose={handleImageClose}>
					<b>Fiche de jeu :</b> <u>{props.item.nom} </u>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Paper>
								<center><img src={props.item.image ? props.item.image : placeholder} width="80%" alt="jeu" /></center>
							</Paper>
						</Grid>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h5" component="h5">Code : {props.item.code}</Typography>
							<Typography gutterBottom variant="h2" component="h2">{props.item.nom}</Typography>
							<Typography gutterBottom variant="h5" component="h5">{props.item.type}</Typography>
							<Typography gutterBottom variant="h6" component="h6">
								{props.item.age &&
									props.item.age
										.split(',')
										.map((e) => <Chip key={e} label={e} color="default" style={{ marginRight: 5 }} />)}
							</Typography>
							<Typography gutterBottom variant="h6" component="h6">
								Mots clés : {props.item.mots_cles &&
									props.item.mots_cles
										.split(',')
										.map((e) => <Chip key={e} label={e.toUpperCase()} color="default" style={{ marginRight: 5 }} />)}
							</Typography>
							<Paper>
								<AppBar position="static">
									<Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
										<Tab className={classes.texteTitle} label="Description" {...a11yProps(0)} />
										<Tab className={classes.texteTitle} label="Caractéristiques" {...a11yProps(1)} />
										<Tab className={classes.texteTitle} label="Dimensions" {...a11yProps(2)} />
										<Tab className={classes.texteTitle} label="Autre" {...a11yProps(3)} />
									</Tabs>
								</AppBar>
								<TabPanel value={value} index={0}>
									<div>{props.item.resume || ''}</div>
									<div>
										<b>Contenu de boite :</b> {props.item.boite &&
											props.item.boite
												.map((e) => <Chip key={e} label={e.nom.toUpperCase() + ' (' + e.nombre + ')'} color="default" style={{ marginRight: 5, marginBottom: 3 }} />)}
									</div>
								</TabPanel>
								<TabPanel value={value} index={1}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Paper>
												<b>Code tarif :</b>{props.item.codeTarif && props.item.codeTarif}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Nombre de joueurs :</b>{props.item.nbrJoueur && props.item.nbrJoueur}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Convenance :</b>{props.item.convenance && props.item.convenance + 'cm'}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Durée :</b>{props.item.duree && props.item.duree}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Mécanisme :</b>{props.item.mecanisme && props.item.mecanisme}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Marque :</b>{props.item.marque && props.item.marque}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Origine :</b>{props.item.origine && props.item.origine}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Lieu d'achat :</b>{props.item.lieu && props.item.lieu}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Typologie :</b>{props.item.typologie && props.item.typologie}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Fabricant :</b>{props.item.fabricant && props.item.fabricant}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Catégorie :</b>{props.item.categorie && props.item.categorie}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												<b>Structure :</b>{props.item.structure && props.item.structure}
											</Paper>
										</Grid>
									</Grid>
								</TabPanel>
								<TabPanel value={value} index={2}>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Paper>
												Hauteur : {props.item.hauteur && props.item.hauteur + 'cm'}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												Largeur : {props.item.largeur && props.item.largeur + 'cm'}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												Longeur : {props.item.longeur && props.item.longeur + 'cm'}
											</Paper>
										</Grid>
										<Grid item xs={6}>
											<Paper>
												Poids : {props.item.poids && props.item.poids + 'cm'}
											</Paper>
										</Grid>
									</Grid>
								</TabPanel>
								<TabPanel value={value} index={3}>
									<b>Liens utiles :</b> {props.item.liens &&
										props.item.liens
											.map((e) => <Chip key={e} label={e.lien} color="default" style={{ marginRight: 5, marginBottom: 3 }} />)}
								</TabPanel>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					{ !checkPermissions() && <Button
						to={`account/bookings/${props.item._id}`}
						component={Link}
						title="Réserver"
						color="secondary"
						variant={'contained'}
					>
						{props.btnText}
					</Button>}
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
